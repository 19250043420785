:root {
  --brand-primary: rgb(12, 23, 56);
  --brand-secondary: rgb(31, 91, 156);
  --brand-tertiary: rgb(18, 18, 18);
  --brand-white: rgb(255, 255, 255);
  --brand-black: rgb(1, 1, 1);
  --brand-gray-10: rgb(231, 233, 240);
  --brand-gray-20: rgb(148, 148, 160);

  --animation-speed-medium: 480ms;
  --animation-speed-fast: 180ms;
  --animation-timing-1: cubic-bezier(0.67, 0.34, 0.1, 0.98);

  --space-large: 2rem; // 24px
  --space-medium: 1.33rem; // 16px
  --space-dmall: 1.17rem; // 14px
  --space-xl: 2.67rem; // 32px
  --space-xxl: 3.33rem; // 40px
  --space-xxxl: 4rem; // 48px
  --space-xxxxl: 4.667rem; // 56px
  --space-xxxxxl: 5.333rem; // 64px
  --space-xs: 1rem; // 12px
  --space-xxs: 0.67rem; // 8px
  --space-xxxs: 0.33rem; // 4px

  --zindex-foreground: 1;
  --zindex-background: -1;
  --zindex-overlay: 2;

  --nav-height: 4rem
}
