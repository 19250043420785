.lynx-radio {
  cursor: pointer;
  position: relative;
  flex-wrap: wrap;

  > .amplify-radio__button {
    position: relative;
    cursor: pointer;

    &:after {
      content: "\2713";
      position: absolute;
      color: var(--brand-white);
      font-size: var(--space-medium);
      visibility: hidden;
    }
  }

  > .amplify-radio__input:checked ~ .amplify-radio__button {
    &:after {
      visibility: visible;
    }
  }

  &.large {
    .amplify-radio__button {
      height: var(--space-large);
      width: var(--space-large);
    }
  }

  &.medium {
    .amplify-radio__button {
      height: var(--space-medium);
      width: var(--space-medium);

      &:after {
        font-size: var(--space-xxs);
      }
    }
  }

  &.small {
    .amplify-radio__button {
      &:after {
        font-size: var(--space-xxs);
      }
    }
  }
}
