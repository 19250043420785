.lynx-tooltip {
  position: relative;

  > .tooltip-card {
    position: absolute;
    display: none;
    z-index: var(--zindex-foreground);

    &.show {
      display: block;
    }
  }
}
