@import "./variables.scss";

@font-face {
  font-family: "Noto Sans";
  src: url("~/src/fonts/NotoSans-Regular.ttf") format(truetype);
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans";
  src: url("~/src/fonts/NotoSans-Bold.ttf") format(truetype);
  font-weight: 700;
}

html {
  font-size: 12px;

  @media (min-width: 480px) {
    font-size: 16px;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
