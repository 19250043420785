.lynx-button {
  &.primary {
    background: var(--brand-primary);
    color: var(--brand-white);

    &:hover {
      background: var(--brand-primary);
      color: var(--brand-white);
    }

    &[disabled] {
      background: var(--brand-gray-10);
      color: var(--brand-gray-20);
    }
  }

  &.secondary {
    background: var(--brand-black);
    color: var(--brand-white);

    &:hover {
      background: var(--brand-black);
      color: var(--brand-white);
    }
  }
}
